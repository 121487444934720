// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 9 ==========================================
:root {
  --c-primary-50: 51, 51, 51;
  --c-primary-100: 51, 51, 51;
  --c-primary-200: 51, 51, 51;
  --c-primary-300: 51, 51, 51;
  --c-primary-400: 51, 51, 51;
  --c-primary-500: 51, 51, 51;
  --c-primary-600: 51, 51, 51;
  --c-primary-700: 51, 51, 51;
  --c-primary-800: 51, 51, 51;
  --c-primary-900: 51, 51, 51;
  // SECONDARY COLOR
  --c-secondary-50: 42, 52, 145;
  --c-secondary-100: 42, 52, 145;
  --c-secondary-200: 42, 52, 145;
  --c-secondary-300: 42, 52, 145;
  --c-secondary-400: 42, 52, 145; 
  --c-secondary-500: 42, 52, 145;
  --c-secondary-600: 42, 52, 145;
  --c-secondary-700: 42, 52, 145;
  --c-secondary-800: 42, 52, 145;
  --c-secondary-900: 42, 52, 145;

  // NEUTRAL COLOR
  --c-neutral-50: 0, 0, 0;
  --c-neutral-100: 0, 0, 0;
  --c-neutral-200: 0, 0, 0;
  --c-neutral-300: 0, 0, 0;
  --c-neutral-400: 0, 0, 0;
  --c-neutral-500: 0, 0, 0;
  --c-neutral-600: 0, 0, 0;
  --c-neutral-700: 0, 0, 0;
  --c-neutral-800: 0, 0, 0;
  --c-neutral-900: 0, 0, 0;
}
