@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.hover\:bg-neutral-100:hover{
    background-color: #f7fafc;
}
.data-table {
    font-size: small;
    line-height: 5px;
}

.filter-text {
    line-height: 0.90rem
}


.product-table-header tr th {
    color: rgba(255, 255, 255, 0.87);
    padding: 5px;
}

.product-table-header tr th select {
    color: rgba(0, 0, 0, 0.87);
}

.vedio-size video, iframe {
    width: 100% !important;
    height: 500px !important;
}
._-wN_b {
    display: none !important;
}
._1JRT_ {
    width: 100vw;
    height: 100vh !important;
}   
._1yWyS {
    display: grid;
    width: 100vw;
    height: 100vh !important;
    place-items: center !important;
}              
._2Yv4j svg {
   margin-left: 0px !important;
}
._GV0NM {
    top: 0px !important;
}

.imageModal{
    max-height: 660px !important;
}